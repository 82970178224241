'use strict';

import 'babel-polyfill';
require('es6-promise').polyfill();

/**
 * Another handy function that comes out of the box in Python.
 *
 * @return bool: true if the string starts with the given prefix
 */
String.prototype.startswith = function (prefix) {
  var re = new RegExp('^' + prefix);
  return re.test(this);
};

/**
 * And yet another
 *
 * @return bool: true if the string ends with the given suffix
 */
String.prototype.endswith = function (suffix) {
  var re = new RegExp(suffix + '$');
  return re.test(this);
};

/**
 * Returns a TitleCased copy of this string.
 */
String.prototype.toTitleCase = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

// Track every page load w/ Heap.
if (window.heap && window.G.user) {
  window.heap.identify(window.G.user.uuid);
  window.heap.addUserProperties({
    name: window.G.user.name,
    email: window.G.user.email,
    shipperUuid: window.G.user.shipperUuid,
  });
}

// Track every page load w/ Mixpanel.
if (window.mixpanel && window.G.user) {
  window.mixpanel.identify(window.G.user.uuid);
  window.mixpanel.people.set({
    $email: window.G.user.email,
    $name: window.G.user.name,
    shipper: window.G.shipper.companyName,
  });
}
